<template>
  <router-view/>
</template>

<style>
html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0 0;
  padding: 0 0;
}
</style>
