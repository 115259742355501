<template>
  <div class="home">
    <p>JS<span class="blinker"></span></p>
  </div>
</template>

<script>
import trianglify from 'trianglify'

export default {
  created () {
    window.addEventListener('resize', this.fillBg)
    window.addEventListener('click', this.fillBg)
    window.addEventListener('wheel', this.fillBg)
    this.fillBg()
  },
  unmounted () {
    window.removeEventListener('resize', this.fillBg)
    window.removeEventListener('click', this.fillBg)
    window.removeEventListener('wheel', this.fillBg)
  },
  methods: {
    fillBg (e) {
      if (document.getElementById('bg') !== null) {
        document.getElementById('bg').remove()
      }
      const pattern = trianglify({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })

      document.body.appendChild(pattern.toCanvas())
      document.getElementsByTagName('canvas')[0].setAttribute('id', 'bg')
      document.getElementsByTagName('canvas')[0].setAttribute('class', 'bg')
      // console.log(document.getElementById('bg'))
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.home {
  display: flex;
  position: absolute;
  /* color: black; */
  /* font-weight: bold;
  font-family: 'Lato';
  font-size: 100px; */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1em;
  line-height: 1;
  text-shadow: 0.2rem 0 0.3rem #ea36af, -0.2rem 0 0.16rem #4ef3f3;
  letter-spacing: 0.125em;

}

p {
  font-family: 'Lato';
  font-size: 100px;
  animation-duration: 0.01s;
  animation-name: textflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.blinker {
  animation-name: blinker;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1,0,0,1);
  animation-duration: 1s;
  display: inline-block;
  width: 4px;
  height: 75px;
  margin-left: 10px;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(white,.3);
  background-color: #ff00d4;
}

@keyframes blinker {
  from { opacity: 0.5; background-color: #000000;} to { opacity: 0.0; background-color: #5ff4ff;}
}

@keyframes textflicker {
  from {
    text-shadow: 2px 0 0 #ea36af, -2px 0 0 #4ef3f3;
  }

  to {
    text-shadow: 1px 0.1px 1px #ea36af, 2px -0.5px 2px #4ef3f3;
  }
}

@keyframes glitch {
  0% {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }

  10% {
    background-position: 4px 0px;
  }

  15% {
    background-position: -8px 0px;
  }

  20% {
    background-position: -4px 0;
  }

  30% {
    background-position: 12px 0;
  }

  40% {
    background-position: -16px 0;
  }

  50% {
    background-position: -24px 0;
  }

  60% {
    background-position: 16px 0;
  }

  70% {
    background-position: 0 22px;
  }

  80% {
    background-position: -40px -10px;
  }

  100% {
    background-position: 0 0;
    filter: hue-rotate(360deg);
  }
}
</style>
